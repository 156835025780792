<template>
  <div>
    <Transition>
    <b-card v-if="waiting"
            overlay
            :img-src="images.instructionImage"
            img-alt="MRZ SEITE NACH UNTEN"
    >
    </b-card>
    <b-card v-else
            :border-variant="(playerBlocked) ? 'danger' : 'success'"
            :header-bg-variant="(playerBlocked) ? 'danger' : 'success'"
            header-text-variant="white"
            class="fat-border"
            align="center">
      <b-card-body class="pb-0">
        <b-card-title>
          <h1 v-if="playerBlocked" class="text-uppercase text-danger ">Kein Zutritt</h1>
          <h1 v-else class="text-uppercase text-success">Hi {{model.SPIELER.V}}</h1>
        </b-card-title>
        <b-card-text>

          <b-iconstack v-if="playerBlocked" font-scale="15">
            <b-icon stacked  icon="arrow-up" variant="danger" scale="0.75"></b-icon>
            <b-icon stacked  icon="slash-circle" variant="danger"></b-icon>
          </b-iconstack>

          <b-iconstack v-else font-scale="15">
            <b-icon stacked  icon="arrow-up" variant="success" scale="0.75"></b-icon>
            <b-icon stacked  icon="circle" variant="success"></b-icon>
          </b-iconstack>

          <p class="mt-1 text-muted"><small>{{status.message}}</small></p>
        </b-card-text>
      </b-card-body>
    </b-card>
    </Transition>

    <!--- THIS IS A HIDDEN FORM
    <form  @submit.prevent="checkPlayer"> --->
      <input name="Vorname" v-model.trim="model.SPIELER.V" type="hidden"/>
      <input name="Nachname" v-model.trim="model.SPIELER.N" type="hidden"/>
      <input id="birthday" v-on:blur="updateOnBlur" v-model="model.SPIELER.D" type="hidden">
      <input id="nationality" v-model="model.SPIELER.A.L" type="hidden">
      <button name="checkplayer" v-show="false" :disabled="isLoading" @click.prevent="checkPlayer" class="btn btn-primary text-white">
        <b-spinner v-if="isLoading" label="Loading..." small></b-spinner><b-icon v-else label="Loading..." icon="search" small></b-icon></button>
    <!--- </form>
     THIS IS A HIDDEN FORM --->

  </div>
</template>

<script>
  export default {
    name:"check",
    data() {
      return {
        images: {
          instructionImage: require('@/assets/scanner_height_v2.gif')
        },
        waiting: true,
        isLoading: false,
        playerBlocked: false,
        status: {
          type: '',
          message: 'figifhsdhf ijslfhj sfisouhf sfhdnow hfd hkojh  oih wohhofih  hf oihohfff efe',
          showBlockedInfo: false
        },

        model: {
          SPERRGRUND: '',
          SPIELER: {
            id: '',
            V: '', //Vorname
            N: '', //Nachname
            G: '', //Geburtsname
            D: '', //Geburtsdatum
            O: '', //Geburtsort
            A: {    //Anschrift
              P: '',  //Postleitzahl
              W: '', //Wohnort
              S: '', //Straße
              H: '', //Hausnummer
              Z: '', //Adresszusatz
              L: '999', //Ländercode
            }
          },
          INFO: {
            title: '',
            dataprivacy: false,
            approve: false,
            email: ''
          },
          ANLASS: [],
          AUFHEBUNGSGRUND: null,
          FRIST_ENDE: {
            DATUM: '',
            'FORMAT-PATTERN': 'yyyy-MM-dd'
          },
        },
      }
    },
    created(){
      this.getUser(this.$store.getters['authentication/uid']).
      then(data => {
        let activeP = data.profiles.filter(item => item.isActive === true)
        if(activeP.length > 0) {
          this.setProfileSession(activeP[0].id);
        }
      }).catch(error => {
        this.$store.dispatch('alert/error',error)
      })
    },
    methods:{
      updateOnBlur(e) {
        this.model.SPIELER.D = this.$options.filters.formatDateFromFormatToFormat('DD.MM.YYYY', 'YYYY-MM-DD', e.target.value);
        this.key += 1;
      },
      checkPlayer() {
        this.status = {type: '', message: ''};
        if(this.checkSearchValid()){
          this.$store.dispatch('loading/isLoading')
          this.getPlayer().then(data => {

            //set data to default in 5 secs
            this.waiting = false;
            setTimeout(() => {
              this.setDefaultData()
            }, 5000);


            if (data['MELDUNGSKATALOG-ITEM'].ART === 'I') {
              this.playerBlocked = false;
              this.status = {
                type: 'success', message: data['MELDUNGSKATALOG-ITEM'].MELDUNG+ ' [Code: '+data['MELDUNGSKATALOG-ITEM'].SCHLUESSEL+']', showBlockedInfo: true };
              // eslint-disable-next-line no-console
              console.log("playerNotBlocked:"+this.status.message);
            } else if(data['MELDUNGSKATALOG-ITEM'].ART === 'W') {
              this.playerBlocked = true;
              this.status = {type: 'danger', message: data['MELDUNGSKATALOG-ITEM'].MELDUNG+ ' [Code: '+data['MELDUNGSKATALOG-ITEM'].SCHLUESSEL+']', showBlockedInfo: true };
              this.sperrInfo = (typeof data['SPERRINFO'] !== 'undefined') ? data['SPERRINFO'] : [];
              // eslint-disable-next-line no-console
              console.log("playerBlocked:"+this.status.message);
            } else if(data['MELDUNGSKATALOG-ITEM'].ART === 'E') {
              this.playerBlocked = true;
              this.status = {type: 'danger', message: 'OASIS meldet:' + data['MELDUNGSKATALOG-ITEM'].MELDUNG + ' [Code: '+data['MELDUNGSKATALOG-ITEM'].SCHLUESSEL+']', showBlockedInfo: false };
              // eslint-disable-next-line no-console
              console.log("warning:"+this.status.message);
            } else {
              this.playerBlocked = true;
              this.status = {
                type: 'warning', message: 'Undefinierte Rückmeldung von OASIS', showBlockedInfo: false };
              // eslint-disable-next-line no-console
              console.log("warning:"+this.status.message);
            }
            this.$store.dispatch('loading/loadingIsFinished')
            this.key++;
          }).catch( error => {
            //this.status = {type: 'warning', message: error, showBlockedInfo: false};
            this.$store.dispatch('alert/error', typeof error['error'] !== 'undefined' ? error['error'] : error);
            this.$store.dispatch('loading/loadingIsFinished')
            this.key++; });

        } else {
          this.status = {type: 'warning', message: 'Bitte geben Sie zunächst die Vorname, Nachname und Geburtsdatum an.', showBlockedInfo: false};
          // eslint-disable-next-line no-console
          console.log("warning:"+this.status.message);
        }

      },
      getPlayer() {
        return new Promise((resolve, reject) => {
          const header = this.authHeader();
          header['Content-Type'] = 'application/json';
          let senddata = {SPIELER: JSON.parse(JSON.stringify(this.model.SPIELER))};
          if (senddata.SPIELER.A.L === '999') delete senddata.SPIELER.A.L;
          const requestOptions = {
            method: 'POST',
            headers: header,
            body: JSON.stringify(senddata)
          };

          let url = '/oasis/check/'+this.$store.getters['authentication/location'];

          fetch(process.env.VUE_APP_API_SERVER + url, requestOptions)
              .then(this.handleResponse)
              .then(response => {
                if (response.error)
                {
                  reject(response)
                } else {
                  resolve(response);
                }
              }, error => {reject(error)});
        })
      },
      checkSearchValid() {
        if (this.model.SPIELER.V !== '' && this.model.SPIELER.N !== '' && this.model.SPIELER.D !== '') {
          return true;
        }

        this.errors = [];

        if (!this.model.SPIELER.V) {
          this.errors.push('V');
        }
        if (!this.model.SPIELER.N) {
          this.errors.push('N');
        }
        if (!this.model.SPIELER.D) {
          this.errors.push('D');
        }
        this.key++;

        return false;
      },
      getUser(id) {
        return new Promise((resolve, reject) => {
          const header = this.authHeader();
          header['Content-Type'] = 'application/json';
          const requestOptions = {
            method: 'GET',
            headers: header,
          };
          fetch(process.env.VUE_APP_API_SERVER + `/api/users/`+id, requestOptions)
              .then(this.handleResponse)
              .then(response => {
                    resolve(response)
                  },
                  error => {
                    reject(error)
                  });
        });
      },
      setProfileSession(id) {
        const header = this.authHeader();
        header['Content-Type'] = 'application/json';
        const requestOptions = {
          method: 'GET',
          headers: header,
        };
        return fetch(process.env.VUE_APP_API_SERVER + `/api/currentprofile/set/`+id, requestOptions)
            .then(this.handleResponse)
            .then(data => {
              return data;
            });
      },
      setDefaultData() {
        this.key++;
        //var d = new Date();
        //var year = d.getFullYear();
        //var month = d.getMonth();
        //var day = d.getDate();
        //var c = new Date(year + 1, month, day);
        this.model = {
          SPERRGRUND:'',
          SPIELER:{
            id:'',
            V:'', //Vorname
            N:'', //Nachname
            G:'', //Geburtsname
            D:'', //Geburtsdatum
            O:'', //Geburtsort
            A:{    //Anschrift
              P:'',  //Postleitzahl
              W:'', //Wohnort
              S:'', //Straße
              H:'', //Hausnummer
              Z:'', //Adresszusatz
              L:'999' //Ländercode
            }
          },
          INFO:{
            title:'',
            approve:false,
            dataprivacy:false,
            email:''
          },
          ANLASS:[],
          FRIST_ENDE:{
            DATUM:'',
            'FORMAT-PATTERN':'dd.MM.yyyy'
          }
        };

        this.isLoading = false;
        this.status = {type:'', message:'', showBlockedInfo:false};
        this.playerBlocked = false;
        this.waiting = true;
        this.errors = [];
      }

    }
    }
</script>

<style scoped>
  .fat-border {
    border: 7px solid;
  }
  /* we will explain what these classes do next! */
  .v-enter-active,
  .v-leave-active {
    transition: opacity 0.5s ease;
  }

  .v-enter-from,
  .v-leave-to {
    opacity: 0;
  }
</style>