<template>
  <div id="app">
    <b-container fluid="sm">
      <b-row align-v="center">
        <b-col sm>
          <img  alt="Socialguard" src="https://go.socialguard.app/img/brand/sg_light.png" width="100%">
        </b-col>
        <b-col sm="7">
          <main>
            <router-view></router-view>
          </main>
        </b-col>
      </b-row>
      <!--- v-if="!$store.getters['authentication/isAuthenticated']" --->
    </b-container>
    <div class="text-center text-muted mt-3">
      © {{year}} Meileh GmbH <a href="https://www.socialguard.app" class="font-weight-bold ml-1" target="_blank">www.socialguard.app</a> <b-icon icon="door-closed" class="mt-3" @click="logout"></b-icon>
    </div>
    <LoadingCard :is-loading="loading.loading" message="loading ..."></LoadingCard>
    <b-alert v-if="alert.message"
             :show="alert.dismissCountDown"
             :variant="alert.type"
             class="sticky"
             dismissible
             @dismissed="alert.dismissCountDown = 0"
             @dismiss-count-down="countDownChanged">{{alert.message}} <span class="text-muted">(schließt in {{dismissCountDown}} Sekunden)</span> </b-alert>
  </div>
</template>

<script>


import LoadingCard from "@/components/LoadingCard";
export default {
  name: 'App',
  components: {
    LoadingCard
  },
  data() {
    return {
      year: new Date().getFullYear(),
      dismissSecs: 8,
      dismissCountDown: 0
    };
  },
  computed: {
    alert () {
      return this.$store.state.alert;
    },
    loading () {
      return this.$store.state.loading;
    }
  },
  methods: {
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    logout() {
      localStorage.removeItem('safelogin')
      this.$store.dispatch('authentication/logout');
    }

  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 1rem;
}

div.sticky {
  position: fixed;
  z-index: 10000;
  width: 99%;
  top:0;
  margin-top: 1rem;
  margin-left: 1rem;
  margin-right: 2rem;
}
  .logo {
    max-width: 320px;
  }

</style>
