export const loading = {
  namespaced: true,
  state: {
    loading: false,
  },
  actions: {
    isLoading({ commit }) {
      commit('loading');
    },
    loadingIsFinished({ commit }) {
      commit('finished');
    }
  },
  mutations: {
    loading(state) {
      state.loading = true;
    },
    finished(state) {
      state.loading = false;
    }
  }
}
