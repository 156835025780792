<template>
  <div>
    <b-form  @submit.prevent="handleSubmit">
      <b-form-input
          placeholder="Benutzername"
          class="mb-1"
          size="lg"
          :disabled="isLoading"
          v-model="login.user"
      ></b-form-input>
      <b-form-input
          placeholder="Passwort"
          class="mb-1"
          size="lg"
          :disabled="isLoading"
          type="password"
          v-model="login.password"
      ></b-form-input>
      <b-form-checkbox
          id="safe-login"
          v-model="safeLogin"
          name="safe-login"
      >
        Autologin
      </b-form-checkbox>
      <b-button variant="primary" block :disabled="isLoading" type="submit" size="lg"><b-spinner v-if="isLoading" label="Loading..." small></b-spinner><span v-else>LOGIN</span></b-button>
    </b-form>
  </div>
</template>

<script>
  export default {
    name:"login",
    data() {
      return {
        login: {
          user: '',
          password: '',
        },
        key: 1,
        isLoading: false,
        submitted: false,
        safeLogin: true
      }
    },
    mounted() {
      let autologin = localStorage.getItem('safelogin');
      if (autologin !== null) {
        this.login.user = atob(autologin).split(':')[0];
        this.login.password = atob(autologin).split(':')[1];
        this.handleSubmit();
      }
    },
    methods: {
      setShowPW() {
        this.showPW = !this.showPW;
        this.key++;
      },
      handleSubmit () {
        //e.preventDefault();
        this.key++;
        this.submitted = true;
        let email = this.login.user;
        let password = this.login.password;
        if(this.safeLogin) {
          localStorage.setItem('safelogin',btoa(email + ':' + password))
        }
        const { dispatch } = this.$store;
        if (email && password) {
          this.isLoading = true;
          dispatch('authentication/login', {email,password}).then(() =>{
                // eslint-disable-next-line no-console
                console.log("login");
                this.isLoading = false;
                this.key++;
              }
          ).catch(() => {
            this.isLoading = false;
            this.key++;
          });
        }
      }
    }
  }
</script>

<style scoped>

</style>