import { userService } from '../_services';
import router from '../router';

export const authentication = {
  namespaced: true,
  state: {
    status: {},
    uid: null,
    roles: [],
    token: null,
    location: null,
    customer: null,
    firstname: '',
    lastname: '',
    email: ''
  },
  actions: {
    login({ dispatch, commit }, { email, password }) {
      return new Promise((resolve, reject) => {
        commit('loginRequest', { email });
        userService.login(email, password)
            .then(
                data => {
                  commit('loginSuccess', data);
                  router.push('/service/check');
                  dispatch('alert/clear', null, {root: true});
                  resolve(data);
                },
                error => {
                  commit('loginFailure', error);
                  dispatch('alert/error', error, {root: true});
                  reject(error);
                }
            );

      });
    },
    logout({ commit }) {
      userService.logout();
      commit('logout');
      router.push('/login');
    },
    onRefresh({ commit }) {
      return new Promise((resolve) => {
        let token = JSON.parse(localStorage.getItem('token'));
        if (token) {
          userService.getUserData()
              .then(
                  data => {
                    if (!data.error) {
                      if (localStorage.getItem('refreshtoken') !== null) userService.refreshToken(JSON.parse(localStorage.getItem('refreshtoken')));
                      commit('refreshSuccess', data);
                    }
                    resolve(data);
                  }
              )
        }
      });
    }
  },
  mutations: {
    loginRequest(state, data) {
      state.status = { loggingIn: true };
      state.token = data.token;
      state.roles = data.roles;
      state.uid = data.uid;
      state.location = data.location;
      state.customer = data.customer;
      state.firstname = data.firstname;
      state.lastname = data.lastname;
      state.email = data.email;
    },
    loginSuccess(state, data) {
      state.status = { loggedIn: true };
      state.token = data.token;
      state.roles = data.roles;
      state.uid = data.uid;
      state.location = data.location;
      state.customer = data.customer;
      state.firstname = data.firstname;
      state.lastname = data.lastname;
      state.email = data.email;
    },
    loginFailure(state) {
      state.status = {};
      state.token = null;
      state.roles = null;
      state.uid = null;
      state.location = null;
      state.customer = null;
      state.firstname = '';
      state.lastname = '';
      state.email = '';
    },
    logout(state) {
      state.status = {};
      state.token = null;
      state.roles = null;
      state.uid = null;
      state.location = null;
      state.customer = null;
      state.firstname = '';
      state.lastname = '';
      state.email = '';
    },
    refreshSuccess(state, data) {
      state.status = { loggedIn: true };
      state.token = data.token;
      state.roles = data.roles;
      state.uid = data.uid;
      state.location = data.location;
      state.customer = data.customer;
      state.firstname = data.firstname;
      state.lastname = data.lastname;
      state.email = data.email;
    },
  },
  getters: {
    isAuthenticated (state) {
      return state.status.loggedIn;
    },
    hasRole (state) {
      return role => {
        return state.roles.indexOf(role) !== -1;
      }
    },
    location (state) {
      return state.location;
    },
    customer (state) {
      return state.customer;
    },
    locationIRI (state) {
      return '/api/locations/'+state.location;
    },
    uid (state) {
      return state.uid;
    },
    iri (state) {
      return '/api/locations/'+state.uid;
    },
    isService(state,getters) {
      return getters.hasRole('ROLE_SERVICE');
    },
    isOperator: (state,getters) => {
      return getters.hasRole('ROLE_OPERATOR');
    },
    isAdmin: (state,getters) => {
      return getters.hasRole('ROLE_ADMIN');
    },
    name(state) {
      return state.firstname+' '+state.lastname;
    },
    firstname(state) {
      return state.firstname;
    },
    lastname(state) {
      return state.lastname;
    },
    state(state) {
      return state;
    },
    email(state) {
      return state.email;
    }
  }
};
